import { ref } from 'vue'
import { defineStore } from 'pinia'
import type NewsArticle from '@/types/NewsArticle'
import { useNewsService } from '@/services/NewsService'
import { addMinutes } from 'date-fns'
import type DashboardAlert from '@/types/DashboardAlert'
import api from '@/services/Api'
import { useSettingsStore } from '@/stores/settings'

export const useNewsStore = defineStore('news', () => {
	const news = ref<NewsArticle[]>([])
	const alerts = ref<DashboardAlert[]>([])

	const lastUpdate = ref<Date>()
	const loading = ref(false)

	const fetchNews = async (): Promise<void> => {
		loading.value = true
		news.value = await useNewsService().getNews()
		loading.value = false
		lastUpdate.value = new Date()
	}

	const fetchAlerts = async () => {
		const { data } = await api.get('/dashboard/alerts', { params: { language: useSettingsStore()?.language || undefined }})
		alerts.value = data
	}

	const refreshIfNecessary = async () => {
		// update if older than 1 hour
		if (!loading.value && (!lastUpdate.value || lastUpdate.value < addMinutes(new Date(), -15))) {
			console.info('news last updated more than 15 minutes ago, refreshing')
			news.value = await useNewsService().getNews()
			lastUpdate.value = new Date()
		}
	}

	// fetchNews()

	return {
		news,
		alerts,
		loading,
		fetchNews,
		fetchAlerts,
		refreshIfNecessary,
	}
})
