import { ref } from 'vue'

const bus = ref(new Map())

/**
 * Event bus for global emitting
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
export const useEventBus = () => {
	const emit = (event: string, ...args: any) => {
		bus.value.set(event, args)
	}

	const alert = (message: string, ...args: any) => {
		emit('alert', message, ...args)
	}

	const unknownErrorAlert = () => {
		alert('errors.unknown', { danger: true, duration: 5000, localize: true })
	}

	const downloadFile = (data: { url?: string, loading?: boolean}) => {
		emit('fileDownload', data)
	}

	return {
		emit,
		alert,
		downloadFile,
		unknownErrorAlert,
		bus,
	}
}
