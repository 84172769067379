<template>
	<div class="toast-container position-fixed top-0 end-0 p-3 pt-5">
		<div
			id="toast"
			class="toast align-items-center border-0 fade"
			:class="{
				'text-bg-dark': !toast.danger,
				'text-bg-primary': toast.danger,
			}"
			role="alert"
			aria-live="assertive"
			aria-atomic="true"
			ref="toastElement"
		>
			<div class="d-flex">
				<div class="toast-body" v-html="toast.message"></div>
				<button
					type="button"
					class="btn-close btn-close-white me-2 m-auto"
					:aria-label="$t('close')"
					@click.prevent="toast.show = false"
				></button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue'
import { useEventBus } from '@/stores/bus'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()
const { bus } = useEventBus()

const toastElement = ref<HTMLDivElement>()

const toast = reactive({
	show: false,
	danger: false,
	message: '',
	hideTimeout: null as any,
})

watch(() => bus.value.get('alert'), (body) => {
	if (toast.hideTimeout) {
		clearTimeout(toast.hideTimeout)
	}

	toast.show = true
	toast.message = body[0]

	const config = body[1] || {
		danger: false,
		duration: 5000,
		localize: false,
	}

	if (config.localize) {
		toast.message = i18n.t(toast.message)
	}

	toast.danger = config.danger || false

	if (config.duration) {
		toast.hideTimeout = setTimeout(() => {
			toast.show = false
		}, config.duration)
	}
})

watch(() => toast.show, (value) => {
	if (!toastElement.value) {
		return
	}

	if (value) {
		toastElement.value.classList.add('show')
		toastElement.value.classList.add('showing')
		setTimeout(() => {
			toastElement.value!.classList.remove('showing')
		}, 100)
		toastElement.value.addEventListener('hidden.bs.toast', () => {
			toast.show = false
			toast.message = ''
		})
	} else {
		toastElement.value.classList.add('showing')
		setTimeout(() => {
			toastElement.value!.classList.remove('show')
		}, 100)
	}
})
</script>
