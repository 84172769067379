import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { useAuthService } from '@/services/AuthService'

import router from '@/router'
import { useSettingsStore } from './settings'
import { computed, nextTick, reactive } from 'vue'
import { useAnalytics } from '@/services/AnalyticsService'
import { useProjectsStore } from '@/stores/projects'

import type User from '@/types/auth/User'
import type { UserAuth } from '@/types/auth/UserAuth'
import type SignInResponse from '@/types/api/SignInResponse'

interface TwoFactorBypassTokens {
	[key: string]: {
		token: string,
		expiresAt: number,
	},
}

const retrieveFromLocalStorage = (): UserAuth | null => {
	const json = window.localStorage.getItem('auth')

	if (json) {
		try {
			return JSON.parse(json)
		} catch (e) {
			return null
		}
	}

	return null
}

const getTwoFactorBypass = (email: string): { token: string, expiresAt: number } | null => {
	const data = localStorage.getItem('twoFactorBypass')

	if (!data) {
		return null
	}

	return (JSON.parse(data) as TwoFactorBypassTokens)[email] || null
}

const setAnalyticsUser = (user: User) => {
	useAnalytics().june.identify({
		userId: user.id,
		traits: {
			email: user.email,
			name: `${user.firstName} ${user.lastName}`,
			company: {
				name: user.company,
			},
			country: user.country,
		},
	})

	useAnalytics().june.group({
		userId: user.id,
		groupId: user.company,
		traits: {
			group_type: 'company',
		},
	})
}

/**
 * Pinia store for JWT auth
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
export const useAuthStore = defineStore('auth', () => {
	const auth = reactive<UserAuth>({
		user: null,
		accessToken: null,
		refreshToken: null,
		accessTokenExpiresAt: null,
		refreshTokenExpiresAt: null,
		permissions: [],
	})

	const user = computed(() => auth.user)

	const isAccessTokenExpired = (): boolean => (auth.accessTokenExpiresAt || 0) < Date.now().valueOf()
	const isRefreshTokenExpired = (): boolean => (auth.refreshTokenExpiresAt || 0) < Date.now().valueOf()
	const isLoggedIn = (): boolean => auth.accessToken !== null && !isAccessTokenExpired()
	const isSuperAdmin = computed(() => auth.user?.roles.some((r) => r.roleName === 'super_admin') || false)

	/**
	 * Requests two-factor auth code (returns sign in intent token)
	 *
	 * @param email
	 * @param password
	 */
	const requestTwoFactorAuthCode = async (email: string, password: string): Promise<{
		twoFactorMethod: number,
		twoFactorRecipient: string | null,
		signInIntentToken: string,
		userLanguage: string | null,
	}> => {
		return useAuthService().requestTwoFactorAuthCode(email, password)
	}

	/**
	 * Signs user in using intent token and two-factor code
	 *
	 * @param signInIntentToken
	 * @param twoFactorAuthCode
	 * @param rememberDevice
	 * @param deviceName
	 * @param isAndroidApp
	 */
	const signIn = async (
		signInIntentToken: string,
		twoFactorAuthCode: string | null,
		rememberDevice: boolean,
		deviceName: string | null,
		isAndroidApp: boolean,
	): Promise<void> => {
		const data = await useAuthService().signIn(signInIntentToken, twoFactorAuthCode, rememberDevice, deviceName, isAndroidApp)
		init(data)
	}

	/**
	 * Signs user in on a remembered device
	 * (requires valid bypass token, call hasValidTwoFactorBypassToken() before!)
	 *
	 * @param email
	 * @param password
	 */
	const signInOnRememberedDevice = async (email: string, password: string): Promise<void> => {
		const { token } = getTwoFactorBypass(email)!
		const data = await useAuthService().signInOnRememberedDevice(email, password, token)
		init(data)
	}

	const signInWithRefreshToken = async (refreshToken: string, expiresAt: number): Promise<void> => {
		const { accessToken, accessTokenExpiresAt } = await useAuthService().refresh(refreshToken)
		auth.accessToken = accessToken
		auth.accessTokenExpiresAt = accessTokenExpiresAt
		auth.refreshToken = refreshToken
		auth.refreshTokenExpiresAt = expiresAt

		await nextTick()

		const user = await useAuthService().getUser()
		auth.user = user
		auth.permissions = user.permissions
		persist()

		useSettingsStore().setUser(user)
		setAnalyticsUser(user)
	}

	/**
	 * Setup/Initialize store with sign in response data
	 *
	 * @param data
	 */
	const init = (data: SignInResponse, newRegistration = false): void => {
		auth.user = data.user
		auth.accessToken = data.accessToken
		auth.refreshToken = data.refreshToken
		auth.accessTokenExpiresAt = data.accessTokenExpiresAt * 1000
		auth.refreshTokenExpiresAt = data.refreshTokenExpiresAt * 1000
		auth.permissions = data.permissions

		useSettingsStore().setUser(data.user)
		setAnalyticsUser(data.user)

		// i18n.locale = data.user.language

		// Save 2FA bypass token (remember device functionality)
		if (data.twoFactorBypassToken) {
			const twoFactorBypassTokenStorage = localStorage.getItem('twoFactorBypass')
			let twoFactorByPassTokens: TwoFactorBypassTokens = {}

			if (twoFactorBypassTokenStorage) {
				twoFactorByPassTokens = JSON.parse(twoFactorBypassTokenStorage)
			}

			twoFactorByPassTokens[data.user.email] = {
				token: data.twoFactorBypassToken,
				expiresAt: data.twoFactorBypassTokenExpiresAt! * 1000,
			}

			localStorage.setItem('twoFactorBypass', JSON.stringify(twoFactorByPassTokens))
		}

		if (newRegistration) {
			useAnalytics().track('Signed up', {
				first_name: data.user.firstName,
				last_name: data.user.lastName,
				email: data.user.email,
			})
		}

		persist()
	}

	const hasValidTwoFactorBypassToken = (email: string): boolean => {
		const bypass = getTwoFactorBypass(email)
		return bypass !== null && bypass.expiresAt > Date.now().valueOf()
	}

	/**
	 * Refreshs access token using refresh token
	 * (returns false if expired or on failure)
	 */
	const refresh = async (): Promise<boolean> => {
		if (!auth.refreshToken || isRefreshTokenExpired()) {
			console.info('no refresh token found or expired, signing out')
			await logout(true)
			return false
		}

		try {
			const refresh = await useAuthService().refresh(auth.refreshToken)
			auth.accessToken = refresh.accessToken
			auth.accessTokenExpiresAt = refresh.accessTokenExpiresAt
			persist()
			return true
		} catch (e) {
			console.error('Couldn\'t refresh access token')
			await logout(true)
			return false
		}
	}

	/**
	 * Returns true if the user has a given permission
	 *
	 * @param permission
	 */
	const hasPermission = (permission: string): boolean => {
		return auth.permissions.some((userPermission) => {
			if (userPermission === '*' || userPermission === permission) {
				return true
			}

			const allowedParts = userPermission.split('.')
			const requestedParts = permission.split('.')

			// Check each part of the requested permission
			for (let index = 0; index < requestedParts.length; index++) {
				// Check if the requested part is allowed
				const allowedEquivalent = allowedParts[index] || null

				if (allowedEquivalent !== '*' && allowedEquivalent !== requestedParts[index]) {
					return false
				}

				if (allowedEquivalent === '*' && index === allowedParts.length - 1) {
					// The last part of the allowed permission is a wildcard,
					// so the requested permission is allowed
					return true
				}
			}

			return true
		})
	}

	/**
	 * Ends session, clears store and local storage auth data
	 */
	const logout = async (rememberDevice = false): Promise<void> => {
		console.info('signing out...')

		try {
			await useAuthService().logout()
		} catch (e) {
			console.info('failed to end php session, might be already ended', e)
		}

		if (!rememberDevice && !isAccessTokenExpired() && auth.accessToken) {
			console.info('access token seems valid, ending session')

			try {
				await useAuthService().endCurrentSession()
			} catch (e) {
				console.warn('failed to end session', e)
			}
		}

		auth.user = null
		auth.accessToken = null
		auth.refreshToken = null
		auth.accessTokenExpiresAt = null
		auth.refreshTokenExpiresAt = null
		persist()

		useProjectsStore().selected = undefined
		useProjectsStore().recent = undefined

		window.localStorage.removeItem('auth')

		if (!rememberDevice) {
			window.localStorage.removeItem('twoFactorBypass')
		}
	}

	const clearTwoFactorByPass = (email?: string) => {
		if (!email) {
			window.localStorage.removeItem('twoFactorBypass')
			return
		}

		const twoFactorBypassTokenStorage = localStorage.getItem('twoFactorBypass')

		if (!twoFactorBypassTokenStorage) {
			window.localStorage.removeItem('twoFactorBypass')
			return
		}

		const twoFactorByPassTokens: TwoFactorBypassTokens = JSON.parse(twoFactorBypassTokenStorage)
		delete twoFactorByPassTokens[email]

		localStorage.setItem('twoFactorBypass', JSON.stringify(twoFactorByPassTokens))
	}

	/**
	 * Restores auth/login (from local storage)
	 * and renews access token if necessary
	 */
	const restoreLogin = async (): Promise<boolean> => {
		if (auth.user) {
			Sentry.setUser({ id: auth.user.id })
			setAnalyticsUser(auth.user!)

			if (isAccessTokenExpired()) {
				// Try to refresh
				return await refresh()
			}

			return true
		} else if (auth.accessToken && !isAccessTokenExpired()) {
			try {
				const user = await useAuthService().getUser()
				auth.user = user
				auth.permissions = user.permissions
				persist()
				return true
			} catch (e) {
				console.warn('error retrieving user', e)
				return false
			}
		} else {
			const login = retrieveFromLocalStorage()

			if (login) {
				auth.user = login.user
				auth.accessToken = login.accessToken
				auth.refreshToken = login.refreshToken
				auth.accessTokenExpiresAt = login.accessTokenExpiresAt
				auth.refreshTokenExpiresAt = login.refreshTokenExpiresAt
				auth.permissions = login.permissions

				if ((auth.accessTokenExpiresAt || 0) < Date.now().valueOf()) {
					if ((auth.refreshTokenExpiresAt || 0) < Date.now().valueOf()) {
						return false
					}

					if (!await refresh()) {
						return false
					}
				}

				if (await valid()) {
					Sentry.setUser({ id: login.user?.id })
					useSettingsStore().setUser(login.user!)
					setAnalyticsUser(login.user!)
					return true
				}
			}
		}

		return false
	}

	/**
	 * Refreshs user data
	 */
	const refreshUser = async (): Promise<void> => {
		try {
			const user = await useAuthService().getUser()
			auth.permissions = user.permissions
			auth.user = user

			persist()
		} catch (e) {
			console.error('error fetching user... signing out')
			logout(true)
			await router.push('/')
		}
	}

	/**
	 * Returns true if session is valid
	 * (checks if session might have been ended remotely)
	 */
	const valid = async (): Promise<boolean> => {
		if (!isLoggedIn()) {
			return false
		}

		try {
			await refreshUser()
			return true
		} catch (e) {
		}

		return false
	}

	/**
	 * Persist the current state in local storage
	 */
	const persist = (): void => {
		window.localStorage.setItem('auth', JSON.stringify(auth))
	}

	return {
		auth,
		user,

		isAccessTokenExpired,
		isRefreshTokenExpired,
		isLoggedIn,
		isSuperAdmin,

		requestTwoFactorAuthCode,
		signIn,
		signInOnRememberedDevice,
		signInWithRefreshToken,
		init,
		hasValidTwoFactorBypassToken,
		refresh,
		hasPermission,
		logout,
		clearTwoFactorByPass,
		restoreLogin,
		refreshUser,
		valid,
		persist,
	}
})
