import axiosInstance from './Api'
import { useAuthStore } from '@/stores/auth'

/**
 * Axios interceptor setup for JWT injection
 * and automatic renewal before sending requests
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
const setupInterceptors = () => {
	axiosInstance.interceptors.request.use(
		async (config) => {
			const authStore = useAuthStore()

			if (authStore.isAccessTokenExpired()) {
				if (authStore.isRefreshTokenExpired()) {
					console.info('refresh token expired, redirecting to login')

					if (!window.location.href.includes('auth/login')) {
						window.location.href = '/v2/auth/login'
					}

					return Promise.reject(new Error('refresh token expired'))
				}

				console.info('trying to renew access token before request', config.url)
				const refreshed = authStore.refresh()

				if (!refreshed) {
					window.location.href = '/v2/auth/login'
					return Promise.reject(new Error('Couldn\'t refresh access token'))
				}
			}

			const token = authStore.auth.accessToken

			if (token) {
				config.headers!.Authorization = token
			}

			return config
		},
		(error) => Promise.reject(error),
	)
	axiosInstance.interceptors.response.use(
		(res) => res,
		async (err) => {
			const authStore = useAuthStore()

			if (!err.config) {
				return Promise.reject()
			}

			const originalConfig = err.config

			if (err.response) {
				// Access Token was expired
				if (err.response.status === 401 && !originalConfig._retry) {
					if (err.response.data?.status === 'sessionExpired') {
						window.location.href = '/v2/auth/login'
						return
					}

					originalConfig._retry = true

					try {
						await authStore.refresh()
						originalConfig.headers.Authorization = authStore.auth.accessToken
						return axiosInstance(originalConfig)
					} catch (_error) {
						return Promise.reject(_error)
					}
				} else if (err.response.status === 401 && err.response.data?.code === 2) {
					window.location.href = '/v2/auth/login'
				}
			}

			return Promise.reject(err)
		},
	)
}

export default setupInterceptors
