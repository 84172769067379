<template>
	<modal-container v-if="downloadModal.show">
		<div class="d-flex justify-content-start">
			<div class="flex-grow-0 flex-shrink-0 me-3">
				<i class="bi bi-cloud-download-fill text-primary"></i>
			</div>
			<div class="flex-grow-1">
				<h1 class="fw-bold fs-6">
					{{ $t('fileDownload.title') }}
				</h1>
				<template v-if="downloadModal.url">
					<p class="text-muted small">
						{{ $t('fileDownload.infoText') }}
					</p>
					<div class="d-flex justify-content-end">
						<a href="#" class="btn btn-light btn-sm me-2" @click.prevent="downloadModal.show = false">
							{{ $t('legacy.modalBtnClose') }}
						</a>
						<a :href="downloadModal.url" target="_blank" download class="btn btn-primary btn-sm" @click="downloadModal.show = false">
							<i class="bi bi-cloud-download"></i>
							{{ $t('fileDownload.downloadBtn') }}
						</a>
					</div>
				</template>
				<template v-else-if="downloadModal.loading">
					<p class="text-muted small">
						{{ $t('fileDownload.pleaseWait') }}
					</p>
					<loading-indicator />
					<div class="d-flex justify-content-end">
						<a href="#" class="btn btn-light btn-sm me-2" @click.prevent="downloadModal.show = false">
							{{ $t('cancel') }}
						</a>
					</div>
				</template>
				<template v-else>
					<p class="text-muted small">
						{{ $t('fileDownload.errorMessage') }}
					</p>
					<div class="d-flex justify-content-end">
						<a href="#" class="btn btn-light btn-sm me-2" @click.prevent="downloadModal.show = false">
							{{ $t('legacy.modalBtnClose') }}
						</a>
					</div>
				</template>
			</div>
		</div>
	</modal-container>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue'

import ModalContainer from '@/components/ModalContainer.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import { useEventBus } from '@/stores/bus'

const { bus } = useEventBus()

const downloadModal = reactive({
	show: false,
	url: null as string | null,
	loading: false,
})

watch(() => bus.value.get('fileDownload'), ([{ url, loading }]: [{ url?: string, loading?: boolean }]) => {
	downloadModal.url = url || null
	downloadModal.loading = loading || false

	if (url && window.open(url, '_blank')) {
		downloadModal.show = false
		return
	}

	downloadModal.show = true
})
</script>
