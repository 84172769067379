import axios from 'axios'

const instance = axios.create({
	baseURL: import.meta.env.VITE_API_BASE,
	headers: {
		...(import.meta.env.DEV ? { Debug: '12345' } : {}),
	},
})

export const unauthenticatedApi = axios.create({
	baseURL: import.meta.env.VITE_API_BASE,
})

export default instance
