import api, { unauthenticatedApi } from '@/services/Api'
import type User from '@/types/auth/User'
import type { UserWithRoles } from '@/types/auth/User'
import { useAuthStore } from '@/stores/auth'
import type Session from '@/types/auth/Session'

/**
 * Service for authentication
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
export class AuthService {

	public async signIn(
		signInIntentToken: string,
		twoFactorAuthCode: string|null,
		rememberDevice: boolean = false,
		deviceName: string|null = null,
		isAndroidApp: boolean = false,
	): Promise<{
		user: UserWithRoles,
		accessToken: string,
		refreshToken: string,
		twoFactorBypassToken: string|null
		accessTokenExpiresAt: number,
		refreshTokenExpiresAt: number,
		twoFactorBypassTokenExpiresAt: number|null,
		permissions: string[],
	}> {
		const { data } = await unauthenticatedApi.post('/auth/sign-in', {
			signInIntentToken,
			twoFactorAuthCode,
			rememberDevice,
			deviceName,
			mobileApp: isAndroidApp ? 'yes' : null,
		})

		return data
	}

	public async requestTwoFactorAuthCode(email: string, password: string): Promise<{
		twoFactorMethod: number,
		twoFactorRecipient: string|null,
		signInIntentToken: string,
		userLanguage: string|null,
	}> {
		const { data } = await unauthenticatedApi.post('/auth/intent', { email, password })
		return data
	}

	public async signInOnRememberedDevice(email: string, password: string, twoFactorBypassToken: string): Promise<{
		user: UserWithRoles,
		accessToken: string,
		refreshToken: string,
		accessTokenExpiresAt: number,
		refreshTokenExpiresAt: number,
		permissions: string[],
	}> {
		const { data } = await unauthenticatedApi.post('/auth/sign-in', {
			email,
			password,
			twoFactorBypassToken,
		})

		return data
	}

	public async validateSignUpToken(userId: string, token: string): Promise<{
		email: string,
		name: string,
		company: string,
		country: string,
	}> {
		const { data } = await unauthenticatedApi.post('/auth/signup/validate', { userId, token })
		return data
	}

	public async signUp(
		userId: string,
		token: string,
		newPassword: string,
		gender: string|null,
		twoFactorAuthMethod: number,
		twoFactorAuthPhoneCountryCode: number|null,
		twoFactorAuthPhone: string|null,
		language: string|null,
	): Promise<string> {
		const { data } = await unauthenticatedApi.post('/auth/signup', {
			userId,
			token,
			newPassword,
			gender,
			twoFactorAuthMethod,
			twoFactorAuthPhoneCountryCode,
			twoFactorAuthPhone,
			language,
		})

		return data.signUpIntentToken
	}

	public async confirmSignUp(userId: string, token: string, code: string): Promise<void> {
		const { data } = await unauthenticatedApi.post('/auth/signup/confirm', {
			userId,
			token,
			code,
		})

		useAuthStore().init(data, true)
	}

	public async refresh(refreshToken: string): Promise<{ accessToken: string, accessTokenExpiresAt: number }> {
		const { data } = await unauthenticatedApi.post(
			'/auth/renew',
			{ refreshToken },
		)

		return {
			accessToken: data.accessToken,
			accessTokenExpiresAt: data.accessTokenExpiresAt * 1000,
		}
	}

	public async getUser(): Promise<UserWithRoles&{permissions: string[]}> {
		const { data } = await api.get('/auth/user')
		return data as UserWithRoles & { permissions: string[] }
	}

	public async updateUser(user: User): Promise<void> {
		await api.patch('/auth/user', user)
	}

	public async updateLanguage(language: string): Promise<void> {
		await api.patch('/auth/user', { language })
	}

	public async updateSettings(settings: {
		dateFormat?: string|null
		numberFormatLocale?: string|null
		skipGuidedTour?: boolean
	}): Promise<void> {
		await api.put('/auth/user/settings', settings)
	}

	public async getSettings(): Promise<{ dateFormat: string|null, numberFormatLocale: string|null, skipGuidedTour: boolean|null }> {
		const { data } = await api.get('/auth/user/settings')
		return data
	}

	public async updateUserPassword(currentPassword: string, newPassword: string): Promise<void> {
		await api.patch('/auth/user/password', { currentPassword, newPassword })
	}

	public async getTwoFactorSettings(): Promise<{
		method: number,
		phoneCountryCode: number|null,
		phone: string|null,
	}> {
		const { data } = await api.get('/auth/2fa')
		return data
	}

	public async saveTwoFactorSettings(settings: {
		method: number,
		phoneCountryCode: number|null,
		phone: string|null,
	}): Promise<string|null> {
		const { data } = await api.put('/auth/2fa', settings)

		if (data.status === 'intentCreated') {
			// return two factor update intent token
			return data.token
		}

		return null
	}

	public async confirmTwoFactorSettings(
		token: string,
		twoFactorAuthCode: string,
	): Promise<void> {
		await api.post('/auth/2fa/confirm', { token, twoFactorAuthCode })
	}

	public async getUserSessions(): Promise<Session[]> {
		const { data } = await api.get('/auth/sessions')
		return data as Session[]
	}

	public async endCurrentSession(): Promise<void> {
		// prevent interceptor from signing out loop if session expired
		await unauthenticatedApi.delete('/auth/session', {
			headers: {
				Authorization: useAuthStore().auth.accessToken,
			},
		})
	}

	public async logout(): Promise<void> {
		await unauthenticatedApi.post('/auth/logout')
	}

	public async endSession(sessionId: string): Promise<void> {
		await api.delete(`/auth/sessions/${sessionId}`)
	}

	public async endAllSessions(): Promise<void> {
		await api.delete('/auth/sessions')
	}

	public async forgotPassword(email: string): Promise<void> {
		await unauthenticatedApi.post('/auth/forgot', {
			email,
		})
	}

	public async confirmPasswordReset(id: string, token: string, newPassword: string): Promise<void> {
		await unauthenticatedApi.post('/auth/password-reset', {
			id,
			token,
			newPassword,
		})
	}

	public async checkPasswordResetToken(id: string, token: string): Promise<void> {
		await unauthenticatedApi.post('/auth/password-reset', {
			id,
			token,
		})
	}
}

const authService = new AuthService()

export const useAuthService = (): AuthService => authService
