import { ref } from 'vue'
import { defineStore } from 'pinia'
import api from '@/services/Api'
import type Project from '@/types/Project'
import { useAuthStore } from '@/stores/auth'

export const useProjectsStore = defineStore('projects', () => {
	const selected = ref<Project>()
	const recent = ref<Project[]>()
	const searchQuery = ref('')

	const searchProjects = async (
		query: string,
		archived = false,
		offset = 0,
		limit = 50,
	): Promise<{ projects: {[customerNumber: string]: Project[]}, moreAvailable: boolean }> => {
		const { data } = await api.get('/projects', {
			params: {
				query: query.length ? query : null,
				archive: archived ? 1 : undefined,
				offset,
				limit,
			},
		})
		return data
	}

	const fetchRecentProjects = async (): Promise<void> => {
		const { data } = await api.get('/projects/recent')
		recent.value = data
	}

	const setProjectById = async (id: number) => {
		const { data } = await api.get(`/projects/${id}`, { params: { logSelection: 'yes' } })
		selected.value = data

		// Move/add the selected project to the top of the recent projects list
		if (recent.value) {
			recent.value = recent.value.filter((project) => project.id !== id)
			recent.value.unshift(data)
			recent.value = recent.value.slice(0, 5)
		}
	}

	const setProject = async (project: Project) => {
		selected.value = project

		// Move/add the selected project to the top of the recent projects list
		if (recent.value) {
			recent.value = recent.value.filter((p) => p.id !== project.id)
			recent.value.unshift(project)
			recent.value = recent.value.slice(0, 5)
		}

		// still fetch the full project details to log the selection
		await api.get(`/projects/${project.id}`, { params: { logSelection: 'yes' } })
	}

	const getProjectFilePrefix = (): string|undefined => {
		if (!selected.value) {
			return undefined
		}

		return selected.value.customerNumber + '-' + selected.value.address.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/-+/g, '-')
	}

	if (useAuthStore().isLoggedIn()) {
		fetchRecentProjects()
	}

	return {
		selected,
		recent,
		searchQuery,
		searchProjects,
		fetchRecentProjects,
		setProject,
		setProjectById,
		getProjectFilePrefix,
	}
})
