import api from '@/services/Api'
import type NewsArticle from '@/types/NewsArticle'
import { useSettingsStore } from '@/stores/settings'

/**
 * Service for news
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
export const useNewsService = () => {
	const getNews = async (): Promise<NewsArticle[]> => {
		const { data } = await api.get('/news', { params: { language: useSettingsStore()?.language || undefined }})
		return data
	}

	return {
		getNews,
	}
}
