<template>
	<div id="top-accent-bar"></div>
	<top-nav v-if="showSidebar && pageReady" />
	<div v-if="showSidebar" v-show="pageReady" id="sidebar-wrapper">
		<sidebar-nav />
		<div>
			<router-view />
			<footer>
				<div class="container-fluid">
					<nav class="text-white d-flex justify-content-end align-items-baseline my-3 list-unstyled">
						<router-link to="/imprint" class="ms-3 small text-secondary">
							{{ $t('legacy.imprint') }}
						</router-link>
						<router-link to="/privacy" class="ms-3 small text-secondary">
							{{ $t('legacy.privacy') }}
						</router-link>
						<router-link to="/tos" class="ms-3 small text-secondary">
							{{ $t('legacy.termsofuse') }}
						</router-link>
					</nav>
				</div>
			</footer>
		</div>
	</div>
	<router-view v-else v-show="pageReady" />
	<toast-container />
	<download-modal />
	<guided-tour v-if="showGuidedTour" @done="showGuidedTour = false" />
	<modal-container v-if="appUpdateDialog.show">
		<h3 class="text-primary fw-bold">{{ $t('availableAppUpdateReloadConfirmation.title') }}</h3>
		<p>
			{{ $t('availableAppUpdateReloadConfirmation.text', { appName }) }}
		</p>
		<div class="d-flex justify-content-end">
			<a :href="appUpdateDialog.targetUrl" class="btn btn-sm btn-primary me-1">{{ $t('yes') }}</a>
			<a href="#" class="btn btn-sm btn-light" @click.prevent="appUpdateDialog.show = false">{{ $t('no') }}</a>
		</div>
	</modal-container>
</template>

<script setup lang="ts">
import { onMounted, provide, reactive, ref, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import TopNav from '@/components/Navigation/TopNav.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import DownloadModal from '@/components/DownloadModal.vue'
import { useAuthStore } from '@/stores/auth'
import SidebarNav from '@/components/Navigation/SidebarNav.vue'
import { useAnalytics } from '@/services/AnalyticsService'
import GuidedTour from '@/components/GuidedTour.vue'
import { useEventBus } from '@/stores/bus'
import router from '@/router'
import ModalContainer from '@/components/ModalContainer.vue'

const appName = import.meta.env.VITE_APP_NAME

const route = useRoute()
const i18n = useI18n()
const auth = useAuthStore()
const { bus } = useEventBus()

const showSidebar = ref(false)
const pageReady = ref(false)
const showGuidedTour = ref(false)
const appUpdateDialog = reactive({
	show: false,
	targetUrl: '',
})

provide('analytics', useAnalytics())

const routeChange = () => {
	showSidebar.value = !(route.meta?.hideSidebar || false)

	if (route.meta?.titleKey) {
		document.title = `${i18n.t(route.meta.titleKey as string).toString()} - ${import.meta.env.VITE_APP_NAME}`
	} else {
		document.title = import.meta.env.VITE_APP_NAME
	}

	useAnalytics().activity()
}

onMounted(async () => {
	routeChange()
	pageReady.value = true
	document.documentElement.lang = i18n.locale.value || 'en'

	router.onError((error, to) => {
		if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
			console.info('App update detected')

			appUpdateDialog.targetUrl = `/v2${to.fullPath.toString()}`
			appUpdateDialog.show = true
		}
	})

	window.addEventListener('focus', () => useAnalytics().activity())
})

watch(() => auth.user?.language, () => {
	if (auth.user?.language && auth.user.language !== i18n.locale.value && i18n.availableLocales.includes(auth.user.language)) {
		i18n.locale.value = auth.user.language
	}
})

watch(() => route.path, routeChange)
watch(() => i18n.locale, () => {
	document.documentElement.lang = i18n.locale.value
})

watch(() => bus.value.get('startGuidedTour'), () => {
	showGuidedTour.value = true
	useAnalytics().track('Guided tour started')
})
</script>
